import React, { useState } from 'react';
import Head from 'next/head';
import { Container, Badge } from 'reactstrap';
import { Collapse, Navbar, NavbarToggler, NavbarBrand, Nav, NavItem, NavLink } from 'reactstrap';
import Menu from 'react-feather/dist/icons/menu';
import Linkedin from 'react-feather/dist/icons/linkedin';
import Facebook from 'react-feather/dist/icons/facebook';
import Twitter from 'react-feather/dist/icons/twitter';


const Navigation: React.FunctionComponent = () => {
  const [open, setOpen] = useState<boolean>(false);
  const toggleOpen = () => setOpen(!open);
  return (
    <header className="u-header">
      <div className="u-header__section">
        <Container>
          <Navbar expand="md" className="u-header__navbar u-header__navbar--no-space">
            <NavbarBrand href="/">
              <img src="/static/img/aimlevents-logo-256x256.png" width="50" height="50" className="d-inline-block align-top" alt="" />
              <span className="u-header__navbar-brand-text">AI &amp; ML Events</span>
            </NavbarBrand>
            <NavbarToggler onClick={toggleOpen}><Menu color="#377dff" /></NavbarToggler>
            <Collapse isOpen={open} navbar className="u-header__navbar-collapse">
              <Nav className="ml-auto u-header__navbar-nav" navbar>
                <NavItem className="u-header__nav-item">
                  <NavLink href='/' className="u-header__nav-link">Home</NavLink>
                </NavItem>
                <NavItem className="u-header__nav-item">
                  <NavLink href='/videos' className="u-header__nav-link">Conference Videos <Badge color="primary">New</Badge></NavLink>
                </NavItem>
                <NavItem className="u-header__nav-item">
                  <NavLink href='/add-event' className="btn btn-sm btn-primary">Add Event</NavLink>
                </NavItem>
              </Nav>
            </Collapse>
          </Navbar>
        </Container>
      </div>
    </header>
  );
}


type Props = { }

const Layout: React.FunctionComponent<Props> = ({ children }) => (
  <div>
    <Head>
      <meta charSet='utf-8' />
      <meta httpEquiv="Content-Type" content="text/html; charset=utf-8" />
      <meta name='viewport' content='initial-scale=1.0, width=device-width' />
      <link rel='stylesheet' href='/static/css/theme.css' type='text/css' media='all' />
      <link href="https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700&display=swap" rel="stylesheet" />
      <link rel="preconnect" href="https://fonts.gstatic.com/" crossOrigin="anonymous" />
      <link rel="preconnect" href="https://www.google-analytics.com" crossOrigin="anonymous" />
      <meta key="og:type" property="og:type" content="website" />
      <meta key="fb:app_id" property="fb:app_id" content="533712397460506" />
      <meta key="twitter:site" property="twitter:site" content="@aimlevents" />
      <meta name="theme-color" content="#377dff" />
      <link rel="alternate" type="application/rss+xml" title="AI &amp; ML Events" href="/feed/rss/" />
    </Head>
    <Navigation />
    {/* <header>
      <nav>
         | {' '}
        <Link href='/list-class'><a>List Example</a></Link> | {' '}
        <Link href='/list-fc'><a>List Example (as Functional Component)</a></Link> | {' '}
        <Link href='/event'><a>Event</a></Link> | {' '}
        <Link href='/about'><a>About</a></Link> | {' '}
      </nav>
    </header> */}
    {children}
      <footer className="container space-top-1">
        <div className="border-bottom">
          <div className="row mb-7">
            <div className="col-lg-3 mb-7 mb-lg-0">
              <div className="d-flex align-self-start flex-column h-100">
                {/* Logo */}
                <a className="d-flex align-items-center mb-3" href="/" aria-label="AI &amp; ML Events">
                  <img src="/static/img/aimlevents-logo-256x256.png" width={128} height={128} alt="AI &amp; ML Events"/>
                </a>
                {/* End Logo */}
              </div>
            </div>
            <div className="d-sm-none d-lg-block col-md-6 col-lg-2 mb-4 mb-md-0">
              {/* <h4 className="h6 font-weight-semi-bold">Events by Country</h4>
              <ul className="list-group list-group-flush list-group-borderless mb-0">
                <li><a className="list-group-item list-group-item-action" href="/?country=United+States">United States</a></li>
                <li><a className="list-group-item list-group-item-action" href="/?country=United+Kingdom">United Kingdom</a></li>
                <li><a className="list-group-item list-group-item-action" href="/?country=India">India</a></li>
                <li><a className="list-group-item list-group-item-action" href="/">All Countries</a></li>
              </ul> */}
            </div>
            <div className="col-sm-6 col-md-6 col-lg-2 mb-4 mb-md-0">
              <h4 className="h6 font-weight-semi-bold">Follow us</h4>
              {/* List Group */}
              <ul className="list-group list-group-flush list-group-borderless mb-0">
                <li><a className="list-group-item list-group-item-action" href="https://linkedin.com/company/aimlevents/" target="_blank" rel="noopener">LinkedIn</a></li>
                <li><a className="list-group-item list-group-item-action" href="https://twitter.com/aimlevents" target="_blank" rel="noopener">Twitter</a></li>
                <li><a className="list-group-item list-group-item-action" href="https://facebook.com/aimlevents/" target="_blank" rel="noopener">Facebook</a></li>
              </ul>
              {/* End List Group */}
            </div>
            <div className="col-md-3 col-lg-2 col-sm-6 col-md-6 col-lg-2 mb-4 mb-md-0">
              <h4 className="h6 font-weight-semi-bold">For Organisers</h4>
              <ul className="list-group list-group-flush list-group-borderless mb-0">
                <li><a className="list-group-item list-group-item-action" href="/add-event">Add Event</a></li>
              </ul>
            </div>
            <div className="col-sm-6 col-md-6 col-lg-2">
              <h4 className="h6 font-weight-semi-bold">Company</h4>
              {/* List Group */}
              <ul className="list-group list-group-flush list-group-borderless mb-0">
                <li><a className="list-group-item list-group-item-action" href="/contact">Contact</a></li>
                <li><a className="list-group-item list-group-item-action" href="/terms-and-conditions">Terms &amp; Conditions</a></li>
                <li><a className="list-group-item list-group-item-action" href="/privacy-policy">Privacy Policy</a></li>
              </ul>
              {/* End List Group */}
            </div>
          </div>
        </div>
        <div className="d-flex justify-content-between align-items-center py-7">
          {/* Copyright */}
          <p className="small text-muted mb-0">2020 &copy; AI &amp; ML Events</p>
          {/* End Copyright */}
          {/* Social Networks */}
          <ul className="list-inline mb-0">
            <li className="list-inline-item">
              <a href="https://linkedin.com/company/aimlevents/" target="_blank" rel="noopener" title="Follow us on LinkedIn">
                <Linkedin size={24} />
              </a>
            </li>
            <li className="list-inline-item">
              <a href="https://twitter.com/aimlevents" target="_blank" rel="noopener" title="Follow us on Twitter">
                <Twitter size={24} />
              </a>
            </li>
            <li className="list-inline-item">
              <a href="https://facebook.com/aimlevents/" target="_blank" rel="noopener" title="Follow us on Facebook">
                <Facebook size={24} />
              </a>
            </li>
          </ul>
          {/* End Social Networks */}
        </div>
      </footer>
  </div>
);

export default Layout;
